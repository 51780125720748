@font-face {
    font-family: "Blair";
    src: url('assets/blairmditc-tt-medium.ttf');
    font-display: swap;

}


//breakpoints
$tablet : 768px;
$desktop : 1024px;

// colors
$white : rgb(255, 255, 255);
$orange: #DF572B;
$grey:#cad1d6;
$black:#28282a;
$lightgrey:#eae9ee;

* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

html,
body {
    position: relative;
    overflow-x: hidden;
}

body,
html,
h1,
h2,
h3,
h4,
a {
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    color: $black;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.03em;
    font-weight: 200;
    font-kerning: normal;
}

//Font styles
.uppercase {
    text-transform: uppercase;
}

.orange {
    color: $orange;
}

a {
    text-decoration: none;
}

li {
    list-style: none;
}

h2 {
    font-weight: 400;
}

.text-wrap {
    max-width: 700px;
}

.skip {
    width: 100%;
    //height: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.skip-link {
    left: -999px;
    color: white;
    top: auto;
    width: 1px;
    height: 0px;
    overflow: hidden;
    z-index: -999;
}

a.skip-link:focus,
a.skip-link:active {
    background: white;
    text-align: center;
    border-bottom: 1px solid black;
    padding: 15px 0;
    text-decoration: underline;
    color: #1b60db;
    width: auto;
    height: auto;
    z-index: 999;

    &:hover {
        background-color: #eae9ee;
        border-bottom: 1px solid white;
    }
}

//Navigation

nav.container-wrap {
    transition: all 300ms ease-in-out;
    position: fixed;
    z-index: 4;
    width: 100%;
    background-color: white;
}

.nav-content {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 8vh;
    background-color: $white;
    transition: all 300ms ease-in-out;
}

.logo-style {

    line-height: 1;
    color: $black;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 12px;
    font-family: 'Blair', sans-serif;
    font-weight: 800;

    &.nav{
        display: none;
    }

    &.show{
        display: inline;
    }

    &.header{
        display: inline;
        font-size: 20px;
        line-height: 1.5;
        @media(min-width:$tablet){
            font-size:27px;
        }
    }

    &.spacing {
        margin: 24px 0;
        font-size: 14px;
    }

    @media screen and (min-width:$tablet) {
        font-size: 16px;
    }
}

.nav-links {
    position: absolute;
    right: 0px;
    height: 35vh;
    top: 8vh;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    transform: translateX(140%);
    transition: transform 0.5s ease-in;
    flex: 1 1 auto;

    &:focus {
        outline: $orange;
    }

    @media screen and (min-width: 930px) {
        transform: translateX(0%);
        width: 40%;
        justify-content: flex-end;
        flex-direction: row;
        height: 8vh;
        top: 0;
    }

    li {
        opacity: 0;
        list-style: none;

        @media screen and (min-width: 930px) {
            opacity: 1;
            padding: 0 10px;

        }

        @media (hover: hover) {
            &:hover {
                border-bottom: 1px solid #000;
            }
        }
    }

    a {
        color: $black;
        text-decoration: none;
        letter-spacing: 2px;
        //font-weight: bold;
        font-size: 16px;
        padding-bottom: 5px;
    }
}

.nav-active {
    transform: translateX(0%);
}

.burger {
    display: block;
    cursor: pointer;
    border: none;

    @media screen and (min-width: 930px) {
        display: none;
    }
}

.burger div {
    width: 25px;
    height: 3px;
    background-color: $grey;
    margin: 5px;
    transition: all 0.3s ease;
}

@keyframes navLinkFade {
    from {
        opacity: 0;
        transform: translateX(50px);
    }

    to {
        opacity: 1;
        transform: translateX(0px);
    }
}

.animateIn {
    transform: translateY(0px);
}

.animateOut {
    transform: translateY(-100%);
}

.toggle {
    .line1 {
        transform: rotate(-45deg) translate(-5px, 6px);
    }

    .line2 {
        opacity: 0;
    }

    .line3 {
        transform: rotate(45deg) translate(-5px, -6px);
    }
}

//Hero header
.big-title {
    font-size: 21px;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 15px 0px;
    line-height: 1.5;

    & .hero {
        margin: 0;
    }

    @media screen and (min-width: $tablet) {
        font-size: 27px;
    }
}

.about {
    display: flex;
}

.heroImage {
    line-height: 34px;
    background-image: url('./assets/pand_midden.jpg');
    background-size: auto 100%;
    background-position: 61% 80%;
    min-height: 470px;
    width: 100%;
    background-repeat: no-repeat;
    display: flex;

    @media screen and (min-width:680px) {
        background-position: center;
        min-height: 600px;
    }

    @media screen and (min-width:1100px){
        background-position: center left;
        background-size: 100% auto;
    }
}

//Generic styling
.container-wrap {
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.container {
    padding: 25px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    max-width: 1400px;

    &.nav {
        @media screen and (min-width: $tablet) {
            margin: 20px 0;
        }
    }

    &.hero {
        padding-top: 114px;

        .logo-style{
            display: inline;
        }

        @media screen and (min-width: $tablet) {
            margin: 20px 0;
            padding-top: 116px;
        }
    }

    &.team {
        padding: 25px 0;

        @media screen and (min-width: $tablet) {
            padding: 0 78px;
            margin: 0px 0;
        }
    }

    &.contact {
        max-width: 1400px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-bottom: 24px;

        .contact-info h3 {
            margin-bottom: 25px;
        }

        a {
            width: 100%;
            @media screen and (min-width:$tablet) {
                width: 50%;
            }
            @media screen and (min-width:1100px) {
                width: 60%;
            }
        }

        .contact-image {
            min-height: 200px;
            background-image: url(./assets/maps.jpg);
            background-size: 120% auto;
            background-position: center center;

            @media screen and (min-width:$tablet) {
                width: 100%;
            }
        }

        @media screen and (min-width: $tablet) {
            padding-bottom: 0;
            flex-direction: row;
            width: 50%;
        }

        @media screen and (min-width: 1100px){
            padding: 0 175px
        }
    }

    @media screen and (min-width: $tablet) {
        padding: 0 88px;
        margin: 80px 0;
    }
    @media screen and (min-width: 1100px){
        padding: 0 175px;
    }
}

//Grid
.grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.grid-item {
    display: flex;
    flex-direction: column;
    position: relative;
    flex: 1 1 auto;
    margin-right: 0px;

    @media screen and (min-width: $tablet) {
        max-width: 49%;
    }

    @media screen and (min-width: $desktop) {
        max-width: 32%;
    }

    &:nth-child(3n) {
        margin-right: 0px;
    }

    @media screen and (min-width:$tablet){
        &:nth-child(odd){
            margin-right:10px;
        }
    }

    @media screen and (min-width: 1008px){
        margin-right: 10px;


    }

    &:last-child {
        margin-right: 0px;
    }

}

.grid-item-image {
    height: auto;
    width: 100%;
    min-width: 250px;
}

.grid-item-content {
    display: flex;
    flex-direction: column;
    margin: 25px 25px 25px;
}

.item-text {
    display: flex;
    flex-direction: row;
    justify-items: flex-start;
}

.medium-title {
    font-weight: bold;
    line-height: 1.7;
    font-size: 18px;
}

.small-title {
    font-weight: 300;
    line-height: 1.7;
    font-size: 16px;
}

.info {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    line-height: 1.7;
    font-size: 12px;

    a {
        font-size: 14px;
        display: block;
        border-bottom: 1px solid white;

        &:hover {
            border-bottom: 1px solid orange;
        }
    }

    @media screen and (min-width: $desktop) {

        font-size: 14px;

        a {
            font-size: 14px;
        }
    }
}

.info-active {
    opacity: 1;
    height: 50px;
}

.contact-button {
    display: inline-block;
    border: none;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    text-align: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: black;
    flex: 2;
    padding: 5px;

    &:focus {
        outline: 1px solid #fff;
        color: $orange;
    }

    &:hover {
        color: $orange;
    }

    &:visited {
        color: black;
    }
}

//footer
footer {
    background-color: #eae9ee;
}

/*# sourceMappingURL=main.scss.map */